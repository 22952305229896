import React, { useEffect } from 'react'
import styled from 'styled-components'
import RegularTypo from '../../typography/RegularTypo'
import Slider from '../../atoms/Slider'
import SelectContainerDesktop from '../../molecules/SelectContainerDesktop'
import SelectContainerMobile from '../../molecules/SelectContainerMobile'
import { borderGray, breakpoints } from '../../utils/theme'

const ChoiceWrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${borderGray};
  justify-content: space-between;

  .SelectContainerMobile {
    display: none;
  }
  .SelectContainerDesktop {
    display: block;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .SelectContainerMobile {
      display: block;
    }
    .SelectContainerDesktop {
      display: none;
    }
  }

`

const SliderWrapper = styled.div`
  z-index: 99999;
  width: calc(100% - 200px);

  @media (max-width: ${breakpoints.mobile}px) {
    width: calc(100% - 184px);
  }

`

export default function Choice({
  marks,
  label,
  units,
  minValue,
  maxValue,
  value,
  setValue,
}) {

  let newVal = value

  useEffect(() => {
    const elem = document.getElementById("scroll634");
    const handleScroll = (e) => {
      e.preventDefault();
      const newValue = () => {
        newVal += e.deltaY * -0.2;
        newVal = Math.floor(Math.min(Math.max(minValue, newVal), maxValue))
        return newVal
      }
      setValue(newValue())
    }

    elem.addEventListener('wheel', handleScroll)

  }, []);

  return (
    <ChoiceWrapper>
      <RegularTypo oneline text={label} />
      <SliderWrapper id="scroll634" >
        <Slider
          marks={marks}
          minValue={minValue}
          maxValue={maxValue}
          value={value}
          setValue={setValue}
        />
      </SliderWrapper>
      <SelectContainerMobile
        units={units}
        minValue={minValue}
        maxValue={maxValue}
        value={value}
        setValue={setValue}
        className='SelectContainerMobile'
      />
      <SelectContainerDesktop
        units={units}
        minValue={minValue}
        maxValue={maxValue}
        value={value}
        setValue={setValue}
        className='SelectContainerDesktop'
      />
    </ChoiceWrapper>
  )
}
